import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  private _window: Window;

  private _isiOSDevices: boolean;
  private _isSafari: boolean;
  private _isBrowser: boolean;

  constructor() {
    const platformId = inject(PLATFORM_ID);

    if (!isPlatformBrowser(platformId)) {
      this._window = {navigator: {userAgent: 'fakeAgent'}, location: {pathname: ''}} as Window;
    } else {
      this._window = window;
    }
    this._isiOSDevices = this._isDetectOs(/ipad|iphone|ipod/i);
    this._isSafari = this._isDetectOs(/^((?!chrome|android).)*safari/i);
    this._isBrowser = isPlatformBrowser(platformId);
  }

  get isiOSDevices() {
    return this._isiOSDevices;
  }

  get isSafari() {
    return this._isSafari;
  }

  /**
   * Return true if application runs on specific OS
   *
   * @private
   */
  private _isDetectOs(regexp: RegExp): boolean {
    return regexp.test(this._window.navigator.userAgent) || (!!this._window.navigator.platform && regexp.test(this._window.navigator.platform));
  }

  get isIpadOsDevices() {
    return this._window.navigator.maxTouchPoints > 2 && /MacIntel/.test(this._window?.navigator?.platform);
  }

  get nativeWindow(): any {
    return this._window;
  }

  /**
   * Use scroll for ios for fix rendering
   */
  public scrollForIOS(): void {
    if (this._isBrowser) {
      if (this.isiOSDevices) {
        setTimeout(() => {
          this.nativeWindow.scrollBy(0, 1);
        }, 100);
      }
    }
  }
}
